<template>
  <div class="container">
    <div class="jo-breadcrumb d-flex align-items-center py-2 mb-3">
      <button
        type="button"
        class="nav-link me-3"
        @click="this.$router.push(`/project/${projectId}`)"
      >
        <font-awesome-icon :icon="['fas', 'arrow-left']" class="nav-icon" />
        上一頁
      </button>
      <p class="mb-0">
        計畫管理
        <font-awesome-icon :icon="['fas', 'angle-double-right']" class="ms-1 me-1" />
        {{ project.name }}
        <font-awesome-icon :icon="['fas', 'angle-double-right']" class="ms-1 me-1" />
        新增個案
      </p>
    </div>
    <div class="jo-title mb-3 pb-2">
      <h2 class="text-center">新增個案</h2>
    </div>
    <div class="row">
      <VeeForm @submit="addCase" class="d-flex align-items-center flex-column">
        <div class="mb-3 col-3">
          <label for="name" class="form-label">編號</label>
          <Field
            type="text"
            class="form-control"
            id="sn"
            name="sn"
            :rules="snRule"
            v-model="editingCase.sn"
          />
          <ErrorMessage name="sn" v-slot="{ message }">
            <div class="d-flex align-items-center mt-1">
              <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="ms-1 me-1" />
              <p class="m-0">{{ message }}</p>
            </div>
          </ErrorMessage>
        </div>
        <div class="mb-3 col-3">
          <label for="name" class="form-label">姓名</label>
          <Field
            type="text"
            class="form-control"
            id="name"
            name="name"
            :rules="nameRule"
            v-model="editingCase.name"
          />
          <ErrorMessage name="name" v-slot="{ message }">
            <div class="d-flex align-items-center mt-1">
              <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="ms-1 me-1" />
              <p class="m-0">{{ message }}</p>
            </div>
          </ErrorMessage>
        </div>
        <div class="mb-3 col-3">
          <label for="birthday" class="form-label">生日</label>
          <Field
            type="number"
            class="form-control"
            name="birthday"
            id="birthday"
            placeholder="請輸入民國年，範例：0900508"
            :rules="birthdayRule"
            v-model="editingCase.birthday"
            @change="republicDateTranslate(editingCase.birthday)"
          />
          <ErrorMessage name="birthday" v-slot="{ message }">
            <div class="d-flex align-items-center mt-1">
              <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="ms-1 me-1" />
              <p class="m-0">{{ message }}</p>
            </div>
          </ErrorMessage>
        </div>
        <div class="mb-3 col-3">
          <label for="years" class="form-label">年齡</label>
          <Field
            type="number"
            class="form-control"
            name="years"
            id="years"
            disabled
            v-model="caseAge"
          />
        </div>
        <div class="mb-3 col-3">
          <label for="gender" class="form-label">性別</label>
          <div class="d-flex justify-content-evenly">
            <div class="form-check">
              <Field
                class="form-check-input"
                type="radio"
                name="gender"
                id="gender1"
                value="male"
                v-model="editingCase.gender"
                :rules="genderRule"
              />
              <label class="form-check-label" for="gender1">
                男性
              </label>
            </div>
            <div class="form-check">
              <Field
                class="form-check-input"
                type="radio"
                name="gender"
                id="gender2"
                value="female"
                v-model="editingCase.gender"
                :rules="genderRule"
              />
              <label class="form-check-label" for="gender2">
                女性
              </label>
            </div>
          </div>
          <ErrorMessage name="gender" v-slot="{ message }">
            <div class="d-flex align-items-center mt-1">
              <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="ms-1 me-1" />
              <p class="m-0">{{ message }}</p>
            </div>
          </ErrorMessage>
        </div>
        <div class="mb-3 col-3">
          <label for="note" class="form-label">備註</label>
          <textarea class="form-control" name="note" id="note" v-model="editingCase.note" />
        </div>
        <div class="mt-3 col-3 text-center">
          <button type="submit" class="btn btn-primary">送出</button>
        </div>
      </VeeForm>
    </div>
  </div>
</template>

<script>
// 載入 firestore
// eslint-disable-next-line object-curly-newline
import { doc, collection, getDoc, getDocs, addDoc } from 'firebase/firestore';
// 載入 lodash
import _ from 'lodash';
// 載入 vee-validate
import { Form as VeeForm, Field, ErrorMessage } from 'vee-validate';
import { db } from '@/assets/firebase';

export default {
  components: {
    VeeForm,
    Field,
    ErrorMessage,
  },
  props: {
    userUid: String,
  },
  data() {
    return {
      projectId: this.$route.params.id,
      project: {},
      cases: [],
      casesName: [],
      casesSn: [],
      editingCase: {
        sn: '',
        name: '',
        birthday: '',
        gender: '',
        note: '',
      },
      caseCurrentYears: 0,
      userCharacter: '',
      todayInRepublic: '',
    };
  },
  methods: {
    getProject() {
      // 準備 async 函式
      async function getData(that) {
        const loader = that.$loading.show();
        try {
          const docRef = doc(db, 'projects', that.projectId);
          const docSnap = await getDoc(docRef);
          // eslint-disable-next-line no-param-reassign
          that.project = _.cloneDeep(docSnap.data());
          // 取得所有個案資料
          that.getCases();
          // 檢查使用者的角色
          that.checkCurrentUserCharacter();
          loader.hide();
        } catch (e) {
          loader.hide();
          that.$swal({
            text: String(e),
            icon: 'error',
          });
        }
      }
      // 執行 async 函式
      getData(this);
    },
    getCases() {
      // 清空 cases
      this.cases = [];
      // 準備 async 函式
      async function getData(that) {
        const loader = that.$loading.show();
        try {
          // 取得所有該計畫的個案
          const querySnapshot = await getDocs(collection(db, 'projects', that.projectId, 'cases'));
          querySnapshot.forEach((caseData) => {
            const casesData = _.cloneDeep(caseData.data());
            casesData.id = caseData.id;
            that.cases.push(casesData);
            // 個案編號列表分開儲存
            that.casesSn.push(casesData.sn);
            // 個案名稱列表分開儲存
            that.casesName.push(casesData.name);
          });
          loader.hide();
        } catch (e) {
          loader.hide();
          that.$swal({
            text: String(e),
            icon: 'error',
          });
        }
      }
      // 執行 async 函式
      getData(this);
    },
    checkCurrentUserCharacter() {
      if (this.project.admin === this.userUid) {
        this.userCharacter = 'admin';
      } else if (this.project.coAdmin.indexOf(this.userUid) !== -1) {
        this.userCharacter = 'coAdmin';
      } else if (this.project.coop.indexOf(this.userUid) !== -1) {
        this.userCharacter = 'coop';
      } else if (this.project.share.indexOf(this.userUid) !== -1) {
        this.$router.push('/projects');
      } else {
        this.$router.push('/projects');
      }
    },
    addCase() {
      const now = Date.now();
      // 準備計畫物件
      const data = {
        sn: this.editingCase.sn,
        name: this.editingCase.name,
        birthday: this.republicDateTranslate(this.editingCase.birthday).getTime(),
        gender: this.editingCase.gender,
        note: this.editingCase.note,
        creater: this.userUid,
        createdAt: now,
        updatedAt: now,
      };
      // 準備 async 函式
      async function setData(that) {
        const loader = that.$loading.show();
        try {
          await addDoc(collection(db, 'projects', that.projectId, 'cases'), data);
          loader.hide();
          that.$router.push(`/project/${that.projectId}`);
        } catch (e) {
          loader.hide();
          that.$swal({
            text: String(e),
            icon: 'error',
          });
        }
      }
      // 執行 async 函式
      setData(this);
    },
    republicDateTranslate(republicDate) {
      const republicYear = Number(republicDate.slice(0, 3));
      const month = Number(republicDate.slice(3, 5));
      const day = Number(republicDate.slice(5, 7));
      const date = new Date();
      date.setFullYear(republicYear + 1911);
      date.setMonth(month - 1);
      date.setDate(day);
      return date;
    },
    // 表單驗證規則
    snRule(value) {
      let isRepeated = false;
      // 檢查是否已填
      if (!value) {
        return '此為必填項目';
      }
      // 檢查名稱是否重複
      this.casesSn.forEach((sn) => {
        if (sn === value) {
          isRepeated = true;
        }
      });
      if (isRepeated) {
        return '編號已重複';
      }
      return true;
    },
    nameRule(value) {
      let isRepeated = false;
      // 檢查是否已填
      if (!value) {
        return '此為必填項目';
      }
      // 檢查名稱是否重複
      this.casesName.forEach((name) => {
        if (name === value) {
          isRepeated = true;
        }
      });
      if (isRepeated) {
        return '名稱已重複';
      }
      return true;
    },
    birthdayRule(value) {
      // 檢查是否已填
      if (!value) {
        return '此為必填項目';
      }
      // 檢查是否滿足七位數
      if (value.length !== 7) {
        return '格式需為民國 yyymmdd 不足補 0';
      }
      // 檢查是否晚於今日
      if (Number(value) >= Number(this.todayInRepublic)) {
        return '生日有誤，請檢查輸入';
      }
      // 分別檢查年月日是否有誤
      const year = Number(this.editingCase.birthday.slice(0, 3));
      const month = Number(this.editingCase.birthday.slice(3, 5));
      const day = Number(this.editingCase.birthday.slice(5, 7));
      if (!year || !month || !day || month > 12 || day > 31) {
        return '生日有誤，請檢查輸入';
      }
      return true;
    },
    genderRule(value) {
      if (value) {
        return true;
      }
      return '此為必填項目';
    },
  },
  mounted() {
    // 計算今天的民國年格式
    const now = new Date();
    const republicEra = `${now.getFullYear() - 1911}`;
    const month = `0${now.getMonth() + 1}`.slice(-2);
    const day = `0${now.getDate()}`.slice(-2);
    this.todayInRepublic = republicEra + month + day;
    // 讀取計畫資料
    this.getProject();
  },
  computed: {
    // 計算個案的年紀
    caseAge() {
      if (this.editingCase.birthday.length === 7) {
        const birthYear = this.republicDateTranslate(this.editingCase.birthday).getFullYear();
        const birthMonth = this.republicDateTranslate(this.editingCase.birthday).getMonth() + 1;
        const birthDate = this.republicDateTranslate(this.editingCase.birthday).getDate();
        const nowYear = new Date().getFullYear();
        const nowMonth = new Date().getMonth() + 1;
        const nowDate = new Date().getDate();
        let age = nowYear - birthYear;
        if (nowMonth - birthMonth < 0 || (nowMonth - birthMonth === 0 && nowDate < birthDate)) {
          age -= 1;
        }
        return age;
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  color: $primary;
}
.nav-link {
  color: $primary;
  font-size: 1rem;
  border-radius: 0.5rem;
  margin-right: 0.5rem;
  cursor: pointer;
  border: none;
  background-color: $bright;
  .nav-icon {
    color: $primary;
    font-size: 1rem;
    margin-bottom: -5px;
    margin: 0 0.5rem 0 0;
  }
  &:hover {
    background-color: $primary;
    text-decoration: none;
    color: white;
    .nav-icon {
      color: white;
    }
  }
}
.jo-breadcrumb {
  border-bottom: 2px solid $primary;
}
.jo-title {
  border-bottom: 2px solid $primary;
}
</style>
